/* src/@partials/content/general/portlet/portlet-header.component.scss */
.kt-portlet-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
}
.kt-portlet-header-sticky {
  margin-top: 0.8em;
}
.kt-portlet__head-label span {
  color: #646c9a;
}
.kt-portlet__head-icon {
  color: #646c9a;
}
/*# sourceMappingURL=portlet-header.component.css.map */
