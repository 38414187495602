/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Template } from '~models/reporte/Template';
import { ApiResponse } from '~shared/interface/ApiResponse';

@Injectable({
	providedIn: 'root',
})
export class ReporteService {
	private readonly _url = `${environment.HOST_REPORTES}/reportes`;

	constructor(private http: HttpClient) {}

	generateExcel(request: Template, codigoReporte: string, codigoSistema: string): Observable<any> {
		return this.http.post<ApiResponse<string>>(`${this._url}/generateExcel/${codigoSistema}/${codigoReporte}`, request).pipe(
			map((apiResponse: ApiResponse<string>) => apiResponse.data),
			catchError(() => of(null))
		);
	}
}
