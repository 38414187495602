/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { TbGrupoReporte } from '~models/maestrosync/TbGrupoReporte';
import { TbReporte } from '~models/maestrosync/TbReporte';
import { ReporteFiltroComponent } from '../reporte-filtro/reporte-filtro.component';
import { ReceivedData } from '~models/reporte/ReceivedData';
import { TbGrupoReporteService } from 'app/services/maestrosync/tbGrupoReporte.service';
import { ReporteUtilService } from '../reporte-util.service';

@Component({
	selector: 'app-reporte-listado',
	templateUrl: './reporte-listado.component.html',
	styleUrl: './reporte-listado.component.scss',
})
export class ReporteListadoComponent implements OnInit, OnDestroy {
	filtroGrupoReportes: TbGrupoReporte[] = [];
	grupoReportes: TbGrupoReporte[] = [];
	private _codSistema = '';
	private _ruc = '';
	private _razonSocial = '';
	private _host = '';
	private _idOficina = -1;

	isLoader = true;

	private readonly _subscription = new Subscription();

	constructor(
		private readonly _cdref: ChangeDetectorRef,
		private readonly _dialog: MatDialog,
		private readonly _tbGrupoReporteService: TbGrupoReporteService,
		private readonly _reporteUtilService: ReporteUtilService
	) {}

	ngOnDestroy(): void {
		this._subscription.unsubscribe();
	}

	ngOnInit(): void {
		window.addEventListener('message', this._handleMessage, false);
	}

	private readonly _handleMessage = (event: MessageEvent): void => {
		if (event.data.cambioOficina) {
			this._idOficina = event.data.cambioOficina;
		} else if (event.data) {
			const receivedData = event.data as ReceivedData;
			this._request(receivedData.codigoSistema);
			this._codSistema = receivedData.codigoSistema;
			this._ruc = receivedData.ruc;
			this._razonSocial = receivedData.razonSocial;
			this._host = receivedData.host;
			this._idOficina = receivedData.idOficina;
		}
		this._reporteUtilService.setIdOficina(this._idOficina);
	};

	private _request(codigoSistema: string): void {
		const request = this._tbGrupoReporteService.findAllByCodigoSistemaAndEstadoCustom(codigoSistema);
		this._subscription.add(
			request.subscribe((e) => {
				this.grupoReportes = e;
				this.filtroGrupoReportes = e;
				this._cdref.detectChanges();
			})
		);
	}

	filterReporte(term: string): void {
		this.filtroGrupoReportes = cloneDeep(this.grupoReportes).filter((grupoReporte) => {
			if (grupoReporte.tbReportes == null) {
				return false;
			}
			const matchingReportes = grupoReporte.tbReportes.filter((reporte: TbReporte) => reporte.nombre.toLowerCase().includes(term.toLowerCase()));
			grupoReporte.tbReportes = matchingReportes;
			return matchingReportes.length > 0;
		});
	}

	openModal(title: string, reporte: TbReporte): void {
		this._dialog.open(ReporteFiltroComponent, {
			width: '65vw',
			data: {
				codSistema: this._codSistema,
				reporte: reporte,
				title: title,
				ruc: this._ruc,
				razonSocial: this._razonSocial,
				host: this._host,
				idOficina: this._idOficina,
			},
			disableClose: true,
		});
	}
}
