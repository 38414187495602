<!--begin: Quick actions -->
<div ngbDropdown placement="bottom" autoClose="outside" class="kt-header__topbar-item">
	<div ngbDropdownToggle class="kt-header__topbar-wrapper">
		<span class="kt-header__topbar-icon" [ngClass]="{ 'kt-header__topbar-icon--warning': iconType === 'warning' }">
			<em *ngIf="!useSVG" [ngClass]="icon"></em>
			<span *ngIf="useSVG" class="kt-svg-icon" [inlineSVG]="icon" [ngClass]="{ 'kt-svg-icon--warning': iconType === 'warning' }"></span>
		</span>
	</div>
	<div
		ngbDropdownMenu
		class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl atajos-sumax">
		<form>
			<!--begin: Head -->
			<div class="kt-head kt-head--skin-{{ skin }}" [ngStyle]="{ 'background-image': 'url(' + bgImage + ')' }">
				<h3 class="kt-head__title">
					Atajos de Sumax
					<span class="kt-space-15"></span>
					<span class="btn btn-success btn-sm btn-bold btn-font-md" (click)="sistemas(0)">Ir a menú principal</span>
				</h3>
			</div>
			<!--end: Head -->
			<!--begin: Grid Nav -->
			<div class="kt-grid-nav kt-grid-nav--skin-{{ gridNavSkin }}">
				<div class="kt-grid-nav__row">
					<a (click)="sistemas(1)" class="kt-grid-nav__item btn">
						<span class="kt-grid-nav__icon" [inlineSVG]="'/assets/media/icons/svg/Shopping/Euro.svg'" (onSVGInserted)="onSVGInserted($event)"></span>
						<span class="kt-grid-nav__title">Ventas</span>
						<span class="kt-grid-nav__desc">Módulo Financiero</span>
					</a>
					<a (click)="sistemas(2)" class="kt-grid-nav__item btn">
						<span
							class="kt-grid-nav__icon"
							[inlineSVG]="'/assets/media/icons/svg/Communication/Mail-attachment.svg'"
							(onSVGInserted)="onSVGInserted($event)"></span>
						<span class="kt-grid-nav__title">Transporte</span>
						<span class="kt-grid-nav__desc">Módulo Operativo</span>
					</a>
				</div>
				<div class="kt-grid-nav__row">
					<a (click)="sistemas(3)" class="kt-grid-nav__item btn">
						<span class="kt-grid-nav__icon" [inlineSVG]="'/assets/media/icons/svg/Shopping/Box2.svg'" (onSVGInserted)="onSVGInserted($event)"></span>
						<span class="kt-grid-nav__title">Aduanas</span>
						<span class="kt-grid-nav__desc">Módulo Aduanero</span>
					</a>
					<a (click)="sistemas(4)" class="kt-grid-nav__item btn">
						<span
							class="kt-grid-nav__icon"
							[inlineSVG]="'/assets/media/icons/svg/Communication/Group.svg'"
							(onSVGInserted)="onSVGInserted($event)"></span>
						<span class="kt-grid-nav__title">Carga</span>
						<span class="kt-grid-nav__desc">Módulo de Carga</span>
					</a>
				</div>
				<!--end: Grid Nav -->
			</div>
		</form>
	</div>
</div>
<!--end: Quick actions -->
