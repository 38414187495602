<div *ngIf="tipo === inputText" class="col-12 estandar-styles">
	<input-text #control [required]="isRequired" [label]="label" [formControl]="$any(formControl)"> </input-text>
</div>
<div *ngIf="tipo === inputNumber" class="col-12 estandar-styles">
	<input-number #control [formControl]="$any(formControl)" [decimals]="decimals" [required]="isRequired" [label]="label"> </input-number>
</div>
<div *ngIf="tipo === ngSelectSimple" class="col-12 estandar-styles">
	<ng-select-simple
		#control
		[required]="isRequired"
		[items]="items"
		[virtualScroll]="isVirtualScroll"
		[clearable]="isClearable"
		[formControl]="$any(formControl)"
		[label]="label">
	</ng-select-simple>
</div>
<div *ngIf="tipo === ngSelectMultiple" class="col-12 estandar-styles">
	<ng-select-multiple
		#control
		[required]="isRequired"
		[items]="items"
		[virtualScroll]="isVirtualScroll"
		[label]="label"
		[formControl]="$any(formControl)">
	</ng-select-multiple>
</div>
<div *ngIf="tipo === datePicker" class="col-12 estandar-styles">
	<date-picker #control [required]="isRequired" [label]="label" [formControl]="$any(formControl)" [validatorMessage]="true"> </date-picker>
</div>
