import { NgOption } from '@ng-select/ng-select';
import {
	addDays as addDaysFns,
	differenceInDays as differenceInDaysFns,
	endOfMonth as endOfMonthFns,
	format as formatFns,
	formatDistance as formatDistanceFns,
	getMonth as getMonthFns,
	getYear as getYearFns,
	isValid as isValidFns,
	parse as parseFns,
	parseISO,
	startOfMonth as startOfMonthFns,
	subDays as subDaysFns,
	subYears,
	lastDayOfMonth,
} from 'date-fns';

export type DateFormat =
	| 'ISO'
	| 'ISODATE'
	| 'ISODATETIME_NO_TIMEZONE'
	| 'DATE'
	| 'ISODATETIME_MAXHOURS'
	| 'DATETIME'
	| 'TIME'
	| 'DATETIME_FULL'
	| 'TIME_FULL';
export class DateUtil {
	private static readonly DATEREGEX = /\d{2}\/\d{2}\/\d{4}/;
	private static readonly TIMEREGEX = /(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])/;
	private static readonly ISO8601 = /^(\d{4}-\d{2}-\d{2})(T(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])(\.\d+)?(Z|[+-]\d{2}:\d{2})?)?$/;

	private static readonly DATE_FORMATS: Record<DateFormat, string> = {
		ISO: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
		ISODATE: 'yyyy-MM-dd',
		ISODATETIME_NO_TIMEZONE: "yyyy-MM-dd'T'HH:mm:ss",
		DATE: 'dd/MM/yyyy',
		TIME: 'HH:mm:ss',
		DATETIME: 'dd/MM/yyyy HH:mm:ss',
		ISODATETIME_MAXHOURS: "yyyy-MM-dd'T'23:59:59",
		DATETIME_FULL: 'yyyy-MM-dd HH:mm:ss.SSSSSS',
		TIME_FULL: 'HH:mm:ss.SSSSSS',
	};
	public static months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'setiembre', 'octubre', 'noviembre', 'diciembre'];

	static formatDate(date: Date | string | null, format: DateFormat = 'DATE'): any {
		if (date) {
			return formatFns(this.toDate(date), this.DATE_FORMATS[format]);
		} else {
			return '';
		}
	}

	static now(format: DateFormat = 'DATE'): unknown {
		return this.format(new Date(), format);
	}

	public static parse(date: string, format: DateFormat): Date {
		return parseFns(date, this.DATE_FORMATS[format], new Date());
	}

	public static format(date: Date, format: DateFormat): string {
		return formatFns(date, this.DATE_FORMATS[format]);
	}

	static isValidDatePickerValue(value: string): boolean {
		if (!value || value.length !== 10) return false;
		return this.isValid(this.parse(value, 'DATE'));
	}

	static isValid(date: string | Date | number): boolean {
		return isValidFns(date);
	}

	static getLastDayFromMonth(input: string | Date, targetFormat: DateFormat = 'DATE'): string {
		return this.formatDate(lastDayOfMonth(this.toDate(input)), targetFormat);
	}

	public static subDays(date: string | Date, days: number): Date {
		const newDate = this.toDate(date);
		return subDaysFns(newDate, days);
	}

	public static addDays(date: string | Date, days: number): Date {
		const newDate = this.toDate(date);
		return addDaysFns(newDate, days);
	}

	static toStrFormat(date: string | Date, targetFormat: DateFormat): string {
		return this.format(this.toDate(date), targetFormat);
	}
	static decreaseYears(date: string, amount: number = 1): string {
		const formatDate = this.getFormat(date);
		if (!formatDate) {
			throw new Error('Error Formato');
		}
		const parsedDate = this.parse(date, formatDate);

		if (!parsedDate) {
			throw new Error('Fecha inválida');
		}

		const modifiedDateWithYear = subYears(parsedDate, amount);
		return this.format(modifiedDateWithYear, 'DATE');
	}

	static toDate(date: string | Date): Date {
		if (date instanceof Date) {
			return date;
		}
		const dateFormat = this.getFormat(date);
		let parsedDate;
		if (dateFormat == 'ISO') {
			parsedDate = parseISO(date);
		} else {
			parsedDate = this.parse(date, dateFormat);
		}
		if (!this.isValid(parsedDate)) {
			throw new Error('Invalid date');
		}
		return parsedDate;
	}

	public static diffDays(later: string | Date, earlier: string | Date): number {
		const laterDate = this.toDate(later);
		const earlierDate = this.toDate(earlier);
		return differenceInDaysFns(laterDate, earlierDate);
	}

	static getYear(date: string | Date = new Date()): number {
		const dateObj = this.toDate(date);
		return getYearFns(dateObj);
	}

	static getMonth(date: string | Date = new Date()): number {
		const dateObj = this.toDate(date);
		return getMonthFns(dateObj);
	}

	static startOfMonth(targetFormat: DateFormat = 'DATE'): string {
		return this.format(startOfMonthFns(new Date()), targetFormat);
	}

	static startOfMonth2(fecha: string | Date = new Date(), targetFormat: DateFormat = 'DATE'): string {
		const date = this.toDate(fecha);
		return this.format(startOfMonthFns(date), targetFormat);
	}

	static endOfMonth(fchIni: string | Date = new Date(), targetFormat: DateFormat = 'DATE'): string {
		const date = this.toDate(fchIni);
		return this.format(endOfMonthFns(date), targetFormat);
	}

	static formatDistance(dateStr: string | Date): string {
		return formatDistanceFns(new Date(), this.toDate(dateStr));
	}

	static getFormat(dateStr: string): DateFormat {
		if (this.ISO8601.test(dateStr)) {
			return 'ISO';
		} else if (this.DATEREGEX.test(dateStr)) {
			return 'DATE';
		} else if (this.TIMEREGEX.test(dateStr)) {
			return 'TIME';
		} else {
			throw new Error('Unknown format');
		}
	}

	static getNgOptionList(): NgOption[] {
		return this.months.map((month, index) => ({
			value: index,
			label: month.charAt(0).toUpperCase() + month.slice(1),
		}));
	}
}
