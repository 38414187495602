/* src/@theme/header/topbar/topbar.component.scss */
:host ::ng-deep .kt-header__topbar > :last-child:not([role=tooltip]) .kt-header__topbar-item {
  margin-right: 0 !important;
}
:host ::ng-deep .kt-header__topbar .kt-header__topbar-item {
  margin-right: 4.5rem !important;
}
@media (max-width: 1024px) {
  .kt-header__topbar {
    top: 50px;
    background-color: transparent;
    left: auto;
  }
}
@media screen and (min-width: 999px) {
  #kt_header_mobile_toggler {
    display: none;
  }
}
.small_screen_menu_button {
  transform: translateY(-5px);
}
.kt-header__topbar {
  padding-right: 0 !important;
}
/*# sourceMappingURL=topbar.component.css.map */
