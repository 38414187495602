/* src/app/modules/reportes/reporte-listado/reporte-listado.component.scss */
.input-search {
  width: 35%;
}
@media (max-width: 768px) {
  .input-search {
    width: 100%;
    margin-bottom: 6px;
  }
}
@media (max-width: 768px) {
  .kt-form__filtration {
    flex-direction: column;
  }
  .input-search {
    order: 1;
  }
  .align-items-center {
    order: 2;
    place-self: end;
  }
}
.kt-portlet__head-title {
  color: #48465b !important;
}
.text-listado {
  font-size: 0.75rem;
}
.contaniner-cards {
  height: 100vh;
  max-height: 100vh;
  padding: 0 20px 0 0;
  overflow-y: auto;
  overflow-x: visible;
  color: #48465b;
}
/*# sourceMappingURL=reporte-listado.component.css.map */
