export const environmentBase = {
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	idToken: 'TokenID',
	su: 'su',
	expires_in: 'expires_in',
	sessionId: 'SessionID',
	refreshToken: 'TokenRefresh',
	maestroSyncToken: 'TokenMaestroSync',
	username: 'username',
	notifications: 'notifications',

	PATH_BASE: '',

	COD_SISTEMA: 'SCA',

	URL_LOGIN: 'https://dev-login.sintad.net.pe/#/auth/login',
	HOST_SECURITY: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-security/api/security',
	HOST_BANCOS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-bancos/api',
	HOST_VENTAS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-ventas/api',
	HOST_COMPRAS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-compras/api',
	HOST_CONFIG: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-config/api',
	HOST_MAESTROS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-maestros/api',
	HOST_MAESTROS_SYNC: 'https://api-cluster-sistemas-internos.sumax.pe/dev/sumax-erp-backend-maestros-sync/api',
	URL_DOCS_OPS: 'http://localhost:4300',
	HOST_CARGA: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-carga/api',
	URL_MAESTROS: 'https://dev-maestros.sintad.net.pe/#/maestros',
	HOST_REPORTES: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-reportes/api',
	HOST_ACUERDO_COMERCIAL: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-acuerdocomercial/api',
	HOST_ADUANAS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-aduanas/api',

	searchTbEntidadDto: {
		idTipoDocumento: 'idTipoDocumento',
		nroDocumento: 'nroDocumento',
	},

	HOST_ROOT: '.sintad.net.pe',
};

export function mergeEnviroments<T>(environmentBase: T, newEnviroment: Partial<T>): T {
	return {
		...environmentBase,
		...newEnviroment,
	};
}
