import { NgModule } from '@angular/core';
import { InputSearchComponent } from './input-search/input-search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TipoComponenteComponent } from './tipo-componente/tipo-componente.component';
import {
	DatePickerModule,
	DateTimePickerEditableModule,
	InputNumberModule,
	InputTextModule,
	NgSelectMultipleModule,
	NgSelectSimpleModule,
} from 'ngx-sumax-erp-component';
import {
	DEFAULT_CONFIG_DATE_PICKER,
	DEFAULT_CONFIG_DATE_TIME_PICKER,
	DEFAULT_CONFIG_INPUT_NUMBER,
	DEFAULT_CONFIG_INPUT_TEXT,
	DEFAULT_CONFIG_NGSELECT_MULTIPLE,
	DEFAULT_CONFIG_NGSELECT_SIMPLE,
} from '~shared/interface/ConfigSumaxComponent.intercaes';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [InputSearchComponent, TipoComponenteComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		InputTextModule.forRoot(DEFAULT_CONFIG_INPUT_TEXT),
		InputNumberModule.forRoot(DEFAULT_CONFIG_INPUT_NUMBER),
		DatePickerModule.forRoot(DEFAULT_CONFIG_DATE_PICKER),
		NgSelectSimpleModule.forRoot(DEFAULT_CONFIG_NGSELECT_SIMPLE),
		NgSelectMultipleModule.forRoot(DEFAULT_CONFIG_NGSELECT_MULTIPLE),
		DateTimePickerEditableModule.forRoot(DEFAULT_CONFIG_DATE_TIME_PICKER),
	],
	exports: [InputSearchComponent, TipoComponenteComponent],
})
export class SharedComponentsModule {}
