/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { TbConfiguracionAsignacion } from '~models/maestrosync/TbConfiguracionAsignacion';
import { ApiResponse } from '~shared/interface/ApiResponse';
import { INgSelectObject } from '~shared/interface/INgSelectObject';

@Injectable({
	providedIn: 'root',
})
export class ConfigurableService {
	constructor(protected _http: HttpClient) {}

	findAllByEstado(url: string): Observable<any[]> {
		return this._http.get<ApiResponse<any[]>>(url).pipe(map((res) => res.data));
	}

	findAllNgSelectByEstado(url: string, label: string): Observable<INgSelectObject<any>[]> {
		return this.findAllByEstado(url).pipe(
			mergeMap((res) => {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return
				return of(res.map((e) => this.convertToNgSelect(e, label)));
			})
		);
	}

	findAllByTbConfiguracionAsignacion(url: string, label: string): Observable<INgSelectObject<any>[]> {
		return this._http.get<ApiResponse<TbConfiguracionAsignacion>>(`${url}`).pipe(
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			map((res) => res.data.tbConfiguracionAsignacionDetalles),
			mergeMap((res) => {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return
				return of(res.map((e) => this.convertToNgSelect(e, label)));
			})
		);
	}

	convertToNgSelect(res: any, label: string): INgSelectObject<any> {
		return {
			...res,
			label: this._formatLabel(res, label),
		};
	}

	private _formatLabel(res: any, label: string): string {
		const labelParts: string[] = label.split('-').map((part) => part.trim());
		const formattedLabels = labelParts.map((part) => this._getProperty(res, part));
		return formattedLabels.join(' - ');
	}

	private _getProperty(obj: any, path: string): string {
		const result = path.split('.').reduce((prev, curr) => prev?.[curr], obj) as string;
		return result;
	}
}
