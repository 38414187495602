/* src/@theme/header/header.component.scss */
.sumax_header {
  height: 50px !important;
  position: relative;
  width: 100%;
  top: 0;
  z-index: 100;
}
.finanzas {
  background-image: url("./media/f2.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.carga {
  background-image: url("./media/f3.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.transporte {
  background-image: url("./media/f1.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.configuracion {
  background-image: url("./media/f1.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.maestros {
  background-image: url("./media/f2.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.kt-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.kt-loading-bar {
  position: absolute;
  width: 100%;
  height: 3px;
  top: 0;
  left: 0;
  z-index: 100;
}
/*# sourceMappingURL=header.component.css.map */
