/* src/@partials/content/general/error/error.component.scss */
:host {
  height: 100%;
}
.kt-error-v403 {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
/*# sourceMappingURL=error.component.css.map */
