import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ReporteUtilService {
	private readonly _idOficina$ = new Subject<number>();

	constructor() {}

	public get idOficina$(): Observable<number> {
		return this._idOficina$.asObservable();
	}

	public setIdOficina(id: number): void {
		this._idOficina$.next(id);
	}
}
