/* src/@theme/subheader/subheader.component.scss */
.kt-subheader {
  display: block;
  position: relative !important;
}
.sumax-subheader {
  top: 0 !important;
  height: 45px !important;
  min-height: 45px !important;
  z-index: 80 !important;
}
.sumax-subheader-container {
  margin: 0;
  padding: 0;
  width: 98%;
}
/*# sourceMappingURL=subheader.component.css.map */
