/* src/app/shared/components/input-search/input-search.component.scss */
.input-search {
  position: relative;
  display: block;
}
.input-search .clear {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1em;
  z-index: 10;
  padding: 0.2em;
  width: 2em;
  font-weight: normal;
  text-align: right;
}
.input-search .input-border {
  border-radius: 8px;
}
/*# sourceMappingURL=input-search.component.css.map */
