/* src/@partials/layout/topbar/select-office/select-office.component.scss */
button {
  position: relative;
  z-index: 10;
  background: none;
  border: none;
  color: #ffffff;
}
mat-select {
  width: 0;
  height: 0;
}
/*# sourceMappingURL=select-office.component.css.map */
