import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportesRoutingModule } from './reportes.routing';
import { PortletModule } from '@partials/content/general/portlet/portlet.module';
import { DatePickerModule, InputTextModule } from 'ngx-sumax-erp-component';
import { DEFAULT_CONFIG_DATE_PICKER, DEFAULT_CONFIG_INPUT_TEXT } from '~shared/interface/ConfigSumaxComponent.intercaes';
import { SharedComponentsModule } from '~shared/components/shared-components.module';
import { ReporteListadoComponent } from './reporte-listado/reporte-listado.component';
import { ReporteCardComponent } from './reporte-card/reporte-card.component';
import { ReporteFiltroComponent } from './reporte-filtro/reporte-filtro.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PartialsModule } from '@partials/partials.module';

@NgModule({
	declarations: [ReporteListadoComponent, ReporteCardComponent, ReporteFiltroComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		ReportesRoutingModule,
		PartialsModule,
		PortletModule,
		InputTextModule.forRoot(DEFAULT_CONFIG_INPUT_TEXT),
		DatePickerModule.forRoot(DEFAULT_CONFIG_DATE_PICKER),
		SharedComponentsModule,
	],
	exports: [ReporteListadoComponent],
})
export class ReportesModule {}
