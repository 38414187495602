/* src/@partials/content/general/portlet/portlet.component.scss */
.kt-portlet {
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  margin-bottom: 0;
}
.kt-portlet-progress {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 120;
  transition: background-color 0.5s ease;
}
.kt-portlet-progress-overlay {
  background-color: rgba(0, 0, 0, 0.3);
}
.kt-portlet-header,
.kt-portlet-footer {
  padding: 0.8em 1.4em !important;
}
/*# sourceMappingURL=portlet.component.css.map */
