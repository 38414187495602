import Swal from 'sweetalert2';

export function showLoading(title = 'Cargando...'): void {
	void Swal.fire({
		title: `${title}`,
		timerProgressBar: true,
		allowOutsideClick: false,
		onBeforeOpen: () => {
			Swal.showLoading();
		},
	});
}

export function hideLoading(): void {
	Swal.close();
}
