import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TbReporte } from '~models/maestrosync/TbReporte';

@Component({
	selector: 'app-reporte-card',
	templateUrl: './reporte-card.component.html',
	styleUrl: './reporte-card.component.scss',
})
export class ReporteCardComponent {
	@Input() reporte!: TbReporte;
	@Input() color!: string;
	@Output() openModal: EventEmitter<void> = new EventEmitter<void>();

	getRgbColor(): string {
		this.color = this.color || '#ebedf2';
		const r = parseInt(this.color.slice(1, 3), 16);
		const g = parseInt(this.color.slice(3, 5), 16);
		const b = parseInt(this.color.slice(5, 7), 16);

		return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + 0.25 + ')';
	}
}
