<kt-portlet autocomplete="off">
	<kt-portlet-header>
		<ng-container ktPortletTitle>
			<h3 class="kt-portlet__head-title py-2 me-2">
				<strong>{{ title }} - </strong>
			</h3>
			<span> {{ reporte.nombre }} </span>
		</ng-container>
	</kt-portlet-header>
	<kt-portlet-body [modalScroll]="true">
		<div class="row" [formGroup]="formGroup">
			<div *ngFor="let component of reporte.tbReporteConfiguraciones; let i = index" class="col-4 mb-2">
				<tipo-componente
					[id]="component.nombreControl"
					[tipo]="component.tbTipoComponente.codigo"
					[isRequired]="component.ctrlObligatorio"
					[label]="component.etiqueta"
					[controlName]="component.nombreControl"
					[controlGroup]="formGroup"
					[items]="component.item || itemDefault$"
					(keyup)="$event.stopPropagation(); focusNextComponent($event, component, i)"></tipo-componente>
			</div>
		</div>
	</kt-portlet-body>
	<kt-portlet-footer class="py-3">
		<div class="row justify-content-md-between px-4">
			<div class="col-auto d-flex align-items-center ps-0">
				<span class="text-danger">* Obligatorio</span>
			</div>
			<div class="col-auto ms-auto d-flex align-items-center flex-wrap my-auto px-0">
				<button
					#export
					type="button"
					(click)="exportar()"
					(keyup.arrowright)="cancelar.focus()"
					(keyup.arrowleft)="focusBackComponent(reporte.tbReporteConfiguraciones.length)"
					[disabled]="isLoadingBtnSave"
					class="btn btn-success m-0"
					[class.kt-spinner]="isLoadingBtnSave"
					[class.kt-spinner--right]="isLoadingBtnSave"
					[class.kt-spinner--md]="isLoadingBtnSave"
					[class.kt-spinner--light]="isLoadingBtnSave">
					<i class="fa fa-file-excel"></i>
					Exportar
				</button>
				<button #cancelar type="button" (keyup.arrowleft)="export.focus()" (click)="closeDialog()" class="btn btn-danger ms-3">Cancelar</button>
			</div>
		</div>
	</kt-portlet-footer>
</kt-portlet>
