import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from 'sumax-erp-schematics';
import { TbGrupoReporte } from '~models/maestrosync/TbGrupoReporte';

@Injectable({
	providedIn: 'root',
})
export class TbGrupoReporteService {
	private readonly _url = `${environment.HOST_MAESTROS_SYNC}/tbgruporeportes`;

	constructor(protected http: HttpClient) {}

	findAllByCodigoSistemaAndEstadoCustom(codigoSistema: string): Observable<TbGrupoReporte[]> {
		return this.http.get<ApiResponse<TbGrupoReporte[]>>(`${this._url}/findAllByCodigoSistemaAndEstadoCustom/${codigoSistema}/true`).pipe(
			map((apiResponse) => {
				return apiResponse.data;
			}),
			map(this._orderGrupoReporteByNroSecuencia.bind(this))
		);
	}

	private _orderGrupoReporteByNroSecuencia(grupoReportes: TbGrupoReporte[]): TbGrupoReporte[] {
		this._sortReportesFromGroups(grupoReportes);
		this._filterReportesByEstado(grupoReportes, true);
		return grupoReportes;
	}

	private _sortReportesFromGroups(grupoReportes: TbGrupoReporte[]): void {
		grupoReportes.forEach((grupo) => {
			if (grupo.tbReportes) {
				grupo.tbReportes.sort((a, b) => a.idReporte - b.idReporte);
			}
		});
	}

	private _filterReportesByEstado(grupoReportes: TbGrupoReporte[], estado: boolean): void {
		grupoReportes.forEach((grupo) => {
			if (grupo.tbReportes) {
				grupo.tbReportes = grupo.tbReportes.filter((reporte) => reporte.estado === estado);
			}
		});
	}
}
