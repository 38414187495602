// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from '@theme/error-page/error-page.component';
import { environment } from 'environments/environment';
import { ReporteListadoComponent } from './modules/reportes/reporte-listado/reporte-listado.component';

// Components

const routes: Routes = [
	{
		path: environment.PATH_BASE,
		children: [
			{
				path: 'reportes',
				component: ReporteListadoComponent,
			},
		],
	},
	{
		path: 'error/403',
		component: ErrorPageComponent,
		data: {
			type: 'error-v6',
			code: 403,
			title: 'Prohibido pasar',
			desc: 'Tranquilo ratón, aún no desarrollamos esto.<br> Please, contact Almircar.',
		},
	},
	{
		path: 'error/acceso-denegado',
		component: ErrorPageComponent,
		data: {
			type: 'error-v6',
			code: 403,
			title: 'Prohibido pasar',
			desc: 'No tiene permisos para ver esta página',
		},
	},
	{ path: '**', redirectTo: environment.PATH_BASE, pathMatch: 'full' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule],
})
export class AppRoutingModule {}
