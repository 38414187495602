/* src/@theme/header/header-title/header-title.component.scss */
.sumax-header-title {
  top: 0 !important;
  z-index: 0 !important;
  width: 80%;
  min-height: 49px !important;
  height: 49px !important;
  box-shadow: 0 0 0 0 !important;
  background-color: transparent !important;
}
.sumax-subtitle {
  color: white !important;
  margin: auto 0 !important;
}
.sumax-titulo {
  color: white !important;
  margin-right: 10px;
}
.kt-header-title {
  display: flex;
  align-items: center;
  flex: 1;
}
.kt-header-title-logo {
  display: flex;
  align-items: center;
}
.kt-header-title-logo-image {
  height: 30px;
  margin-right: 5px;
}
.kt-header-title-logo-text {
  margin: 0;
  color: #fff;
  font-size: 1em;
}
.kt-header-title-logo-separator {
  display: block;
  height: 22px;
  width: 1px;
  background-color: #fff;
  border-radius: 2px;
  margin: 0 10px;
}
.kt-header-title-text {
  color: #fff;
  margin: 0;
  font-size: 1em;
}
/*# sourceMappingURL=header-title.component.css.map */
