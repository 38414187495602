import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NgbDropdownModule, NgbNavModule, NgbTooltipModule, NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';

//import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { CoreModule } from '@core/core.module';
import { SplashScreenComponent, QuickActionComponent, UserProfileComponent } from './layout/index';

//import { InlineSVGModule } from 'ng-inline-svg';

import { NgSelectModule } from '@ng-select/ng-select';
//import { NgxMaskModule } from 'ngx-mask';

import { SelectOfficeComponent } from './layout/topbar/select-office/select-office.component';
import { ErrorComponent } from './content/general/error/error.component';

@NgModule({
	declarations: [
		// topbar components
		SplashScreenComponent,
		ErrorComponent,
		QuickActionComponent,
		UserProfileComponent,
		SelectOfficeComponent,
	],
	exports: [
		// topbar components
		SplashScreenComponent,
		QuickActionComponent,
		UserProfileComponent,
		SelectOfficeComponent,
		ErrorComponent,

		// angular material modules
		MatButtonModule,
		MatTableModule,
		MatTabsModule,
		MatDividerModule,
		MatListModule,
		MatSliderModule,
		MatPaginatorModule,
		MatCheckboxModule,
		MatTooltipModule,
		MatDialogModule,
		MatRadioModule,
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		//PerfectScrollbarModule,
		//InlineSVGModule,
		CoreModule,
		// angular material modules
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule,
		MatDividerModule,
		MatListModule,
		MatSliderModule,
		NgSelectModule,
		MatDialogModule,
		//NgxMaskModule.forRoot(),
		// ng-bootstrap modules
		NgbDropdownModule,
		NgbNavModule,
		NgbTooltipModule,
		NgbDatepickerModule,
		NgbTimepickerModule,
	],
})
export class PartialsModule {}
