<!-- <kt-splash-screen *ngIf="isLoader"></kt-splash-screen> -->
<kt-portlet>
	<kt-portlet-header>
		<ng-container ktPortletTitle class="ps-0">
			<div class="me-3">
				<i class="flaticon2-poll-symbol fa-2x"></i>
			</div>
			<h3 class="kt-portlet__head-title py-2 me-2">
				<strong>Reportes</strong>
			</h3>
			&nbsp;
			<span class="text-listado pt-2 ps-1"> Listado </span>
		</ng-container>
	</kt-portlet-header>
	<kt-portlet-body class="pt-2 pb-4">
		<div class="kt-form__filtration mb-0">
			<div class="mt-2">
				<div class="input-search pt-2">
					<app-input-search (valueChange)="filterReporte($event)"></app-input-search>
				</div>
				<div class="contaniner-cards">
					<ng-container *ngFor="let grupoReporte of filtroGrupoReportes">
						<div *ngIf="grupoReporte.tbReportes && grupoReporte.tbReportes.length > 0" class="mt-3">
							<h4 class="text-center kt-portlet__head-title mb-0">
								<strong>{{ grupoReporte.nombre }}</strong>
							</h4>
							<div class="d-flex row row-cols-md-5 row-cols-sm-3 mt-1 pb-3">
								<ng-container *ngFor="let reporte of grupoReporte.tbReportes">
									<app-reporte-card
										[reporte]="reporte"
										[color]="grupoReporte.color"
										class="col mt-3"
										(openModal)="openModal(grupoReporte.nombre, reporte)"></app-reporte-card>
								</ng-container>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</kt-portlet-body>
</kt-portlet>
