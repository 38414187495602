import { createAction, props } from '@ngrx/store';
import { SciFormularioAccionListado } from '../models/SciFormularioAccionListado';

export const cargarAccesos = createAction('[Accesos] Cargar Accesos');

export const cargarAccesosSuccess = createAction(
	'[Accesos] Cargar Accesos Success',
	props<{ sciFormularioAccionListado: SciFormularioAccionListado[] }>()
);

export const cargarAccesosError = createAction('[Accesos] Cargar Accesos Error', props<{ payload: any }>());

export const validarAccesoFormulario = createAction('[Accesos] Validar Accesos Formulario');
