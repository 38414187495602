import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-input-search',
	templateUrl: './input-search.component.html',
	styleUrl: './input-search.component.scss',
})
export class InputSearchComponent implements OnDestroy {
	@Output() valueChange = new EventEmitter<string>();

	formControl = new UntypedFormControl('');
	subscription!: Subscription;

	constructor() {
		this.subscription = this.formControl.valueChanges.subscribe((value: string) => {
			this.valueChange.emit(value.trim());
		});
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}
}
