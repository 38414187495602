/* src/app/modules/reportes/reporte-card/reporte-card.component.scss */
.reporte-card {
  border-radius: 0.85rem;
  border: 1px solid #ebedf2;
}
.reporte-card:hover {
  transition: all 0.3s;
  cursor: pointer;
  background-color: rgba(77, 89, 149, 0.06);
}
.reporte-card-header {
  border-radius: calc(0.85rem - 1px) calc(0.85rem - 1px) 0 0;
}
.reporte-card-body {
  height: 8em;
  min-height: 8em;
  max-height: 8em;
  white-space: normal;
  padding: 0.25em 1.25em;
}
/*# sourceMappingURL=reporte-card.component.css.map */
