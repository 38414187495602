// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// Module
import { CoreModule } from '@core/core.module';
// Portlet
import { PortletComponent } from './portlet.component';
import { PortletHeaderComponent } from './portlet-header.component';
import { PortletBodyComponent } from './portlet-body.component';
import { PortletFooterComponent } from './portlet-footer.component';
import { KtPortletTools } from './ktPortletTools.directive';
import { KtPortletIcon } from './ktPortletIcon.directive';
import { KtPortletTitle } from './ktPortletTitle.directive';
import { KtPortletSticky } from './ktPortletSticky.directive';

@NgModule({
	imports: [CommonModule, CoreModule, MatProgressSpinnerModule, MatProgressBarModule],
	declarations: [
		PortletComponent,
		PortletHeaderComponent,
		PortletBodyComponent,
		PortletFooterComponent,
		KtPortletTools,
		KtPortletTitle,
		KtPortletIcon,
		KtPortletSticky,
	],
	exports: [
		PortletComponent,
		PortletHeaderComponent,
		PortletBodyComponent,
		PortletFooterComponent,
		KtPortletTools,
		KtPortletTitle,
		KtPortletIcon,
		KtPortletSticky,
	],
})
export class PortletModule {}
