/* src/@theme/base/base.component.scss */
kt-base {
  opacity: 0;
  height: 100%;
}
kt-base .kt-grid--root {
  height: 100%;
}
.kt-page--loaded kt-base {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}
@media (min-width: 999px) {
  .sumax-content-wrapper {
    padding-top: 95px !important;
  }
  .sumax-content-wrapper .sumax-content {
    padding: 20px 0 !important;
  }
}
.kt-base {
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.kt-base-header {
  background-color: #fff;
}
.kt-base-body {
  overflow: hidden auto;
  padding-top: 1em;
  padding-bottom: 1em;
  width: 100%;
}
/*# sourceMappingURL=base.component.css.map */
