/* angular:styles/component:css;04575a0b0709d4ef1c5982db88549ff24bcff92fb5d184df04d19743acc6d5f5;/builds/sumax/erp/sumax-erp-frontend/gen/sumax-erp-frontend-reportes/src/@partials/content/general/portlet/portlet-body.component.ts */
.kt-portlet-body {
  display: block;
}
.kt-portlet-body-scroll {
  padding: 0.5em 1.4em !important;
  overflow: hidden auto;
}
.kt-portlet-body-modal {
  padding: 0.5em 1.4em !important;
}
/*# sourceMappingURL=portlet-body.component.css.map */
