import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { EnumComponente } from '~shared/enums/EnumComponente';
import { INgSelectObject } from '~shared/interface/INgSelectObject';

export const inputText = EnumComponente.INPUTTEXT;
export const inputNumber = EnumComponente.INPUTNUMBER;
export const ngSelectSimple = EnumComponente.NGSELECTSIMPLE;
export const ngSelectMultiple = EnumComponente.NGSELECTMULTIPLE;
export const datePicker = EnumComponente.DATEPICKER;

@Component({
	selector: 'tipo-componente',
	templateUrl: './tipo-componente.component.html',
	styles: [
		`
			.estandar-styles {
				font-size: 13px;
			}
		`,
	],
})
export class TipoComponenteComponent implements OnInit {
	@Input() tipo!: string;
	@Input() isRequired = false;
	@Input() label!: string;
	@Input() isSubmitted = false;
	@Input() inputMask!: string;
	@Input() isTextRight = false;
	@Input() maxLength!: number;
	@Input() controlName!: string;
	@Input() controlGroup!: FormGroup;
	@Input() items: Observable<INgSelectObject<any>[]> = of([]);
	@Input() decimals!: number;
	@Input() isVirtualScroll = false;
	@Input() isClearable = false;
	@Input() isDisabled = false;

	formControl: AbstractControl = new FormControl(null);

	inputText = EnumComponente.INPUTTEXT;
	inputNumber = EnumComponente.INPUTNUMBER;
	ngSelectSimple = EnumComponente.NGSELECTSIMPLE;
	ngSelectMultiple = EnumComponente.NGSELECTMULTIPLE;
	datePicker = EnumComponente.DATEPICKER;

	constructor() {}

	ngOnInit(): void {
		this.formControl = this.controlGroup.get(this.controlName) as FormControl;
	}
}
