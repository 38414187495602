import { Action, createReducer, on } from '@ngrx/store';
import { SciOficina, SciUsuarioOficinaPerfil } from '~models/config/';
import {
	cambiarOficinaSeleccionada,
	cargarOficinas,
	cargarOficinasError,
	cargarOficinasSuccess,
	deshabilitarComboOficinas,
	habilitarComboOficinas,
} from '../actions/oficinas.actions';

export interface OficinasState {
	oficinas: SciUsuarioOficinaPerfil[];
	oficina: SciOficina | null;
	loaded: boolean;
	disabled: boolean;
	loading: boolean;
	error: any;
}

export const oficinasInitialState: OficinasState = {
	oficinas: [],
	oficina: null,
	loaded: false,
	disabled: false,
	loading: false,
	error: null,
};

const _oficinasReducer = createReducer(
	oficinasInitialState,

	on(cargarOficinas, (state) => ({ ...state, loading: true })),

	on(cargarOficinasSuccess, (state, { sciUsuarioOficinaPerfil }) => ({
		...state,
		loading: false,
		loaded: true,
		oficinas: [...sciUsuarioOficinaPerfil],
	})),

	on(cambiarOficinaSeleccionada, (state, { sciOficina }) => ({
		...state,
		loading: false,
		loaded: true,
		oficina: { ...sciOficina },
	})),

	on(cargarOficinasError, (state, { payload }) => ({
		...state,
		loading: false,
		loaded: false,
		error: {
			url: payload.url,
			name: payload.name,
			message: payload.message,
		},
	})),

	on(habilitarComboOficinas, (state) => ({ ...state, disabled: false })),

	on(deshabilitarComboOficinas, (state) => ({ ...state, disabled: true }))
);

export function oficinasReducer(state: OficinasState | undefined, action: Action) {
	return _oficinasReducer(state, action);
}
