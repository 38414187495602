/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigurableService } from 'app/services/configurable/configurable.service';
import { environment } from 'environments/environment';
import { find } from 'lodash';
import { BehaviorSubject, firstValueFrom, forkJoin, Observable, of, Subscription } from 'rxjs';
import { TbReporte } from '~models/maestrosync/TbReporte';
import { TbReporteConfiguracion } from '~models/maestrosync/TbReporteConfiguracion';
import { Template } from '~models/reporte/Template';
import { EnumComponente } from '~shared/enums/EnumComponente';
import { EnumDate } from '~shared/enums/EnumDate';
import { EnumMethod } from '~shared/enums/EnumMethod';
import { EnumTypeOrigenDato } from '~shared/enums/EnumTypeOrigenDato';
import { INgSelectObject } from '~shared/interface/INgSelectObject';
import { CookiesService } from '~shared/services/coookies.service';
import { MessageUtilService } from '~shared/services/message-util.service';
import { hideLoading, showLoading } from '~shared/utils/LoadingUtil';
import { DateUtil } from '~shared/utils/DateUtil';
import { ReporteService } from 'app/services/reportes/reporte.service';
import { ReporteUtilService } from '../reporte-util.service';

const formControls: { [key: string]: FormControl } = {};

const dateUtilMethods: { [key: string]: () => string } = {
	[EnumDate.STARTMONTH]: () => DateUtil.startOfMonth(),
	[EnumDate.ENDMONTH]: () => DateUtil.endOfMonth(),
};

type TbTabla = {
	[key: string]: unknown;
	codigo: string;
};

export interface Endpoint {
	url: string;
	label: string;
}

@Component({
	selector: 'app-reporte-filtro',
	templateUrl: './reporte-filtro.component.html',
	styleUrl: './reporte-filtro.component.scss',
})
export class ReporteFiltroComponent implements OnInit, OnDestroy {
	@ViewChild('export', { static: false }) exportElement!: ElementRef;

	reporte!: TbReporte;
	title!: string;
	private _formGroup!: FormGroup;
	isLoadingBtnSave = false;

	dataResultBodyParams: { [key: string]: any } = {};
	dataForm: { [key: string]: any } = {};

	inputText = EnumComponente.INPUTTEXT;
	inputNumber = EnumComponente.INPUTNUMBER;
	ngSelectSimple = EnumComponente.NGSELECTSIMPLE;
	ngSelectMultiple = EnumComponente.NGSELECTMULTIPLE;
	datePicker = EnumComponente.DATEPICKER;

	itemDefault$: Observable<INgSelectObject<any>[]> = of([]);

	private readonly _endpoints: Endpoint[] = [];

	isDataDefault: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private readonly _ruc = '';
	private readonly _razonSocial = '';
	private readonly _codSistema = '';
	private readonly _host = '';
	private readonly _nombreSistema = '';
	private _idOficina = -1;

	private readonly _subscription = new Subscription();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: Record<string, any>,
		private readonly dialogRef: MatDialogRef<ReporteFiltroComponent>,
		private readonly _fb: FormBuilder,
		private readonly _configService: ConfigurableService,
		private readonly _cdref: ChangeDetectorRef,
		private readonly _messageUtilService: MessageUtilService,
		private readonly _cookiesService: CookiesService,
		private readonly _reporteService: ReporteService,
		private readonly renderer: Renderer2,
		private readonly _reporteUtilService: ReporteUtilService
	) {
		this.reporte = data.reporte as TbReporte;
		this.reporte.tbReporteConfiguraciones.sort((a, b) => a.nroSecuencia - b.nroSecuencia);
		this.title = data.title as string;
		this._ruc = data.ruc;
		this._razonSocial = data.razonSocial;
		this._codSistema = data.codSistema;
		this._nombreSistema = data.reporte.sciSistema.titulo;
		this._host = data.host;
		this._idOficina = data.idOficina;

		this._subscription.add(
			_reporteUtilService.idOficina$.subscribe((id: number) => {
				this._idOficina = id;
			})
		);
	}

	ngOnDestroy(): void {
		this._subscription.unsubscribe();
	}

	ngOnInit(): void {
		this._createForm();
	}

	private _createForm(): void {
		this.reporte.tbReporteConfiguraciones.forEach((config) => {
			const defaultValue = config.valorDefecto || null;

			if (config.datoControl != null) {
				config.datoControl = config.datoControl.trim();
			}

			if (config.tbTipoComponente.codigo === this.datePicker) {
				const validators = config.ctrlObligatorio ? [Validators.required] : [];
				formControls[config.nombreControl] = new FormControl(defaultValue ? dateUtilMethods[config.valorDefecto]() : null, validators);
			} else if ([this.ngSelectSimple, this.ngSelectMultiple].includes(config.tbTipoComponente.codigo as EnumComponente)) {
				if (config.tbCatalogoReporte) {
					const endpoint = {
						url: config.tbCatalogoReporte.apiListado,
						label: config.tbCatalogoReporte.datoListado,
					};
					this._endpoints.push(endpoint);
				}
				const validators = config.ctrlObligatorio ? [Validators.required] : [];
				formControls[config.nombreControl] = new FormControl(null, validators);
			} else {
				const validators = config.ctrlObligatorio ? [Validators.required] : [];
				formControls[config.nombreControl] = new FormControl(defaultValue, validators);
			}
		});
		this._formGroup = this._fb.group(formControls);
		this._llenarDatos();
	}

	private _defaultNgSelect(e: TbReporteConfiguracion, value = 'codigo'): void {
		this._subscription.add(
			e.item.subscribe((c: TbTabla[]) => {
				if (Array.isArray(c)) {
					const findValue = find(c, (t) => t[value] === e.valorDefecto);
					if (findValue) {
						this.formGroup.get(e.nombreControl)?.setValue(findValue);
						this._cdref.detectChanges();
					}
				}
			})
		);
	}

	private _llenarDatos(): void {
		const observables = this._endpoints.map((endpoint) => {
			if (endpoint.url.includes('tbconfiguracionasignacion')) {
				return this._configService.findAllByTbConfiguracionAsignacion(endpoint.url, endpoint.label);
			} else return this._configService.findAllNgSelectByEstado(endpoint.url, endpoint.label);
		});
		this._subscription.add(
			forkJoin(observables).subscribe((res) => {
				const listaExpresionRegular = /\[\s*.+\s*\]/;
				let index = 0;
				this.reporte.tbReporteConfiguraciones.forEach((e) => {
					if (
						(e.tbTipoComponente.codigo == EnumComponente.NGSELECTSIMPLE || e.tbTipoComponente.codigo == EnumComponente.NGSELECTMULTIPLE) &&
						e.tbCatalogoReporte
					) {
						e.item = of(res[index]);
						if (e.valorDefecto) this._defaultNgSelect(e);
						index++;
					} else if (e.tbCatalogoReporte == null && listaExpresionRegular.test(e.objetoListado)) {
						const jsonString = e.objetoListado;
						const items: Array<{ [key: string]: string }> = JSON.parse(jsonString);
						e.item = of(items.map((c) => this._configService.convertToNgSelect(c, 'label')));
						if (e.valorDefecto) this._defaultNgSelect(e, 'value');
					}
				});
				this.isDataDefault.next(true);
			})
		);
	}

	async exportar(): Promise<void> {
		this._setForm();
		if (this._isValidForm()) {
			this.isLoadingBtnSave = true;
			const reportData = this._getReportData();
			try {
				await this.exportExcel(reportData, this.reporte);
			} finally {
				this.isLoadingBtnSave = false;
			}
		}
	}

	private _setForm(): void {
		this.reporte.tbReporteConfiguraciones.forEach((e) => {
			const value = this.formGroup.get(e.nombreControl)?.value;

			const dataMap = (data: { [key: string]: any }, key: string, field: string) => {
				const fields = field.split('.');
				const getValue = (obj: any, keys: string[]) => {
					return keys.reduce((acc, key) => acc?.[key], obj);
				};
				data[key] = Array.isArray(value) ? value.map((v) => getValue(v, fields)) : getValue(value, fields);
			};

			switch (e.datoControl) {
				case null:
				case '':
					if (e.tbTipoComponente.codigo == EnumComponente.DATEPICKER) {
						if (e.valorDefecto == EnumDate.STARTMONTH) {
							this.dataForm[e.nombreControl] = value;
							this.dataResultBodyParams[e.nombreControl] = DateUtil.toStrFormat(value as string, 'ISODATETIME_NO_TIMEZONE');
						} else if (e.valorDefecto == EnumDate.ENDMONTH) {
							this.dataForm[e.nombreControl] = value;
							this.dataResultBodyParams[e.nombreControl] = DateUtil.toStrFormat(value as string, 'ISODATETIME_MAXHOURS');
						}
					} else {
						this.dataForm[e.nombreControl] = value;
						this.dataResultBodyParams[e.nombreControl] = value;
					}
					break;
				case 'codigo':
					dataMap(this.dataResultBodyParams, e.nombreControl, 'codigo');
					dataMap(this.dataForm, e.nombreControl, 'codigo');
					break;
				default:
					if (e.datoControl.includes('id') || e.datoControl.includes('value')) {
						const id = this._convertString(e.datoControl);
						dataMap(this.dataResultBodyParams, e.nombreControl, id);
						dataMap(this.dataForm, e.nombreControl, id);
					}

					break;
			}
		});
		this.dataResultBodyParams['codigoReporte'] = this.reporte.codigo;
	}

	private _getReportData(): Template {
		const titulo = this.reporte.nombre;
		const subtitulo = this._getSubtitulo(this.reporte.subtitulo);
		const url = `${this._host}/${this.reporte.apiListado}`;
		const token = this._cookiesService.getItem(environment.idToken);

		return {
			dato: {
				origenDatos: [
					{
						alias: 'Registro 1',
						url,
						method: EnumMethod.POST,
						type: EnumTypeOrigenDato.OBJECT,
						security: {
							type: 'API',
							token,
						},
						bodyParams: { ...this.dataResultBodyParams, idOficina: this._idOficina },
					},
				],
			},
			reporte: this._getReportePart(titulo, subtitulo, this._ruc, this._razonSocial),
		};
	}

	private _getSubtitulo(cad: string): string {
		const result = cad.replace(/\[([^\]]+)\]/g, (match, p1) => {
			return this.dataForm[p1] || match;
		});
		return result;
	}

	private _getReportePart(titulo: string, subtitulo: string, nroDocumento: string, razonSocial: string): any {
		return {
			type: {
				type: 'dinamico',
				control: {
					control: 'poi',
					formato: 'xlsx',
					data: {
						titulo,
						subtitulo,
						sheetName: `REGISTRO DE ${this._nombreSistema.toUpperCase()}`,
						cliente: {
							nroDocumento,
							razonSocial,
						},
					},
				},
			},
		};
	}

	exportExcel = async (request: Template, { codigo, nombre }: TbReporte): Promise<void> => {
		showLoading('Generando Reporte...');
		const data = await firstValueFrom(this._reporteService.generateExcel(request, codigo, this._codSistema));
		if (data) {
			const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`;
			const downloadLink = document.createElement('a');
			downloadLink.href = linkSource;
			downloadLink.download = `${nombre}.xlsx`;
			downloadLink.click();
		}
		hideLoading();
	};

	private _convertString(datoControl: string): string {
		if (typeof datoControl === 'string') {
			return datoControl.trim();
		} else {
			// eslint-disable-next-line no-console
			console.error('Error en id: ', datoControl);
			return '';
		}
	}

	private _isValidForm(): boolean {
		if (!this.formGroup.valid) {
			this.reporte.tbReporteConfiguraciones.forEach((e) => {
				if (!this.formGroup.get(e.nombreControl)?.valid) {
					this._messageUtilService.warning(`${e.etiqueta} es requerido`);
				}
			});
			return false;
		} else {
			let fechaInicio: string | null = null;
			let fechaFin: string | null = null;
			let etiquetaFechaInicio = '';
			let etiquetaFechaFin = '';
			for (const e of this.reporte.tbReporteConfiguraciones) {
				if (e.valorDefecto == EnumDate.STARTMONTH) {
					etiquetaFechaInicio = e.etiqueta;
					fechaInicio = DateUtil.toStrFormat(this.formGroup.get(e.nombreControl)?.value as string, 'ISODATE');
				} else if (e.valorDefecto == EnumDate.ENDMONTH) {
					etiquetaFechaFin = e.etiqueta;
					fechaFin = DateUtil.toStrFormat(this.formGroup.get(e.nombreControl)?.value as string, 'ISODATE');
				}
				if (fechaInicio !== null && fechaFin !== null && fechaInicio > fechaFin) {
					this._messageUtilService.warning(`${etiquetaFechaInicio} no puede ser mayor a ${etiquetaFechaFin}`);
					return false;
				}
			}
			return true;
		}
	}

	focusNextComponent(event: KeyboardEvent, config: TbReporteConfiguracion, i: number): void {
		let input: HTMLElement;

		const isInputComponent = (config: TbReporteConfiguracion) =>
			[EnumComponente.DATEPICKER as string, EnumComponente.INPUTNUMBER, EnumComponente.INPUTTEXT].includes(config.tbTipoComponente.codigo);

		const isSelectComponent = (config: TbReporteConfiguracion) =>
			[EnumComponente.NGSELECTSIMPLE as string, EnumComponente.NGSELECTMULTIPLE].includes(config.tbTipoComponente.codigo);

		switch (event.key) {
			case 'Enter':
				if (i + 1 < this.reporte.tbReporteConfiguraciones.length) {
					const codigo = this.reporte.tbReporteConfiguraciones[i + 1].nombreControl;
					input = document.getElementById(codigo) as HTMLElement;
					this.renderer.selectRootElement(input.querySelector('input')).focus();
				} else if (i == this.reporte.tbReporteConfiguraciones.length - 1) {
					this.exportElement.nativeElement.focus();
				}
				this.setDefaultDate(config, this.reporte.tbReporteConfiguraciones[i + 1]);
				break;
			case 'ArrowUp':
				if (isInputComponent(config)) {
					this.focusBackComponent(i);
				}
				break;
			case 'ArrowLeft':
				if (isSelectComponent(config)) {
					this.focusBackComponent(i);
				}
				break;
		}
	}

	setDefaultDate(config: TbReporteConfiguracion, tbReporteConfigNext: TbReporteConfiguracion | null): void {
		if (config.valorDefecto === EnumDate.STARTMONTH) {
			const currentControlValue = String(this.formGroup.get(config.nombreControl)?.value);
			const lastDayOfMonth = DateUtil.getLastDayFromMonth(currentControlValue);
			this.formGroup.get(tbReporteConfigNext?.nombreControl ?? '')?.setValue(lastDayOfMonth);
		}
	}

	focusBackComponent(i: number): void {
		if (i - 1 >= 0) {
			const codigo = this.reporte.tbReporteConfiguraciones[i - 1].nombreControl;
			const inputBack = document.getElementById(codigo) as HTMLElement;
			this.renderer.selectRootElement(inputBack.querySelector('input')).focus();
		}
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

	get formGroup(): FormGroup {
		return this._formGroup;
	}
}
